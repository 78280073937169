
import { createTheme, lighten } from '@mui/material/styles';
const noneShadows = new Array(23).fill('none');
const fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", sans-serif;'

const primaryColor = '#756f00'
export const borderColor = '#d0d7de'
const secondaryColor = '#464646'
const theme = createTheme({
    shape: {
        borderRadius: 3,
    },
    shadows: [
        'none',
        '0px 0px 10px rgba(0, 0, 0, 0.1)', // 修改了默认的阴影样式
        ...noneShadows,
    ],
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: { 
                    borderColor: borderColor, 
                },
            },
        }
    },
    palette: {
        primary: {
            main: primaryColor,
            contrastText: '#ffffff' 
        },
        secondary: {
            main: secondaryColor,
            contrastText: '#ffffff'
        },
        error: {
            main: '#c2185b',
        },
        wechat:{
            main:'#07C160',
            contrastText: '#ffffff' 
        }
    },
    typography: {
        fontFamily: fontFamily,
        body1: {
            fontSize: '0.875rem',
        },
        body2: {
            fontSize: '0.875rem',
            color: '#9E9E9E',
        },
        body3: {
            fontSize: '0.75rem',
        },
        subtitle2: {
            fontSize: '0.875rem',
        },
        h4: {
            fontSize: '1rem',
            color: '#444444',
        },
        h3: {
            fontSize: '1.5rem',
            color: '#333333',
        }
    },
});
export default theme;